export const economy = {
  title: 'Услуги экономиста',
  meta: {
    title: 'Услуги экономиста в Минске - Финансовая консультация от опытных специалистов',
    description:
      'LegaLex предлагает услуги опытного экономиста в Минске. Узнать цены на консультацию и сопровождение от специалиста по финансам можно на нашем сайте.',
  },
  caption: 'Умные решения для финансового<span class="font-medium text-blue_light"> роста</span>',
  services: [
    {
      caption: 'Бизнес-план',
      description: '',
      tables: [
        {
          title: '',
          rows: [
            {
              caption: 'Бизнес-план инвестиционного проекта',
              price: 'от 1000,00р в зависимости от сложности',
            },
            {
              caption: 'Бизнес-план развития на 1 год или на 5 лет',
              price: 'от 300,00р',
            },
            {
              caption: 'Бизнес-план для индивидуального предпринимателя',
              price: 'от 300,00р',
            },
          ],
        },
      ],
    },
    {
      caption: 'Составление и сдача статистики',
      description: '',
      tables: [
        {
          title: '',
          rows: [
            {
              caption: 'Составление и сдача статистики (за 1 вид статистики)',
              price: 'от 20,00',
            },
          ],
        },
      ],
    },
    {
      caption: 'Разработка локальных правовых актов и иной документации',
      description: '',
      tables: [
        {
          title: '',
          rows: [
            {
              caption: 'Разработка штатного расписания',
              price: 'от 50,00',
            },
            {
              caption: 'Разработка положения по оплате труда',
              price: 'от 100,00',
            },
            {
              caption: 'Разработка положения по премированию',
              price: 'от 100,00',
            },
            {
              caption: 'Разработка положения по оказанию материальной помощи',
              price: 'от 100,00',
            },
            {
              caption: 'Разработка экономического обоснования',
              price: 'Договорная',
            },
            {
              caption: 'Приказы по оплате труда',
              price: 'от 10,00',
            },
          ],
        },
      ],
    },
    {
      caption: 'Полное экономическое сопровождение организации',
      description: '',
      tables: [
        {
          title: '',
          rows: [
            {
              caption: 'Полное экономическое сопровождение организации численностью до 20 человек',
              price: 'от 300,00',
            },
            {
              caption: 'Полное экономическое сопровождение организации численностью до 50 человек',
              price: 'от 500,00',
            },
          ],
        },
      ],
    },
    {
      caption: 'Составление калькуляций',
      description: '',
      tables: [
        {
          title: '',
          rows: [
            {
              caption: 'Составление калькуляций (за 1 калькуляцию)',
              price: 'от 50,00',
            },
          ],
        },
      ],
    },
    {
      caption: 'Прочие экономические услуги',
      description: '',
      tables: [
        {
          title: '',
          rows: [
            {
              caption: 'Прочие экономические услуги',
              price: 'В зависимости от сложности',
            },
          ],
        },
      ],
    },
  ],
}
